import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    margin: `${theme.spacing(2.5)} ${theme.spacing(0)} ${theme.spacing(6)}`,
    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(5)} ${theme.spacing(0)}`,
    },
    [theme.breakpoints.up("lg")]: {
      margin: `${theme.spacing(6)} ${theme.spacing(0)}`,
    },
  },
  layout: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: "1px solid #4B5563",
    "&:first-child": {
      paddingTop: 0,
    },
    "&:last-child": {
      borderBottom: "none",
      paddingBottom: 0,
    },
    "& ul": {
      marginTop: theme.spacing(1),
    },
  },
  question: {
    "&.MuiTypography-root": {
      fontFamily: "Montserrat",
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
  },
  address: {
    fontStyle: "normal",
    "& .address-heading": {
      fontWeight: 600,
    },
  },
  dropInHours: {
    listStyleType: "none",
    paddingLeft: 0,
    "& .bold": {
      fontWeight: 600,
    },
  },
}));

export default useStyles;
