import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    "& h2": {
      marginBottom: 0,
    },
    rowGap: theme.spacing(1.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    "& a": {
      color: "#0C8DFF",
      "&:hover": {
        color: "#0c51ff",
      },
    },
  },
  label: {
    fontWeight: 600,
    marginRight: theme.spacing(0.75),
  },
  divider: {
    backgroundColor: "#4B5563",
  },
}));

export default useStyles;
