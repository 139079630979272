export interface IResourcesData {
  shelter?: IResource[] | null;
  food?: IResource[] | null;
  mental?: IResource[] | null;
  health?: IResource[] | null;
  lgbtq?: IResource[] | null;
  transportation?: IResource[] | null;
  utility?: IResource[] | null;
  legal?: IResource[] | null;
  clothing?: IResource[] | null;
  technology?: IResource[] | null;
  job?: IResource[] | null;
  community?: IResource[] | null;
  dpc?: IResource[] | null;
}

export interface IResource {
  organization: string;
  description: string;
  website: string;
  social?: string | null;
  contact?: string | null;
  address?: string | null;
  notes?: string | null;
}

export const resourceSections = [
  "DPC Resources",
  "Shelter",
  "Food and Meal Programs",
  "Mental Health and Wellness",
  "Health Clinics",
  "LGBTQ+",
  "Transportation",
  "Utility",
  "Legal Resources",
  "Clothing",
  "Technology Support",
  "Job Offerings",
  "Community Activities",
];

export const getSectionData = (data: IResourcesData, title: string) => {
  switch (title) {
    case "DPC Resources":
      return data.dpc;
    case "Shelter":
      return data.shelter;
    case "Food and Meal Programs":
      return data.food;
    case "Mental Health and Wellness":
      return data.mental;
    case "Health Clinics":
      return data.health;
    case "LGBTQ+":
      return data.lgbtq;
    case "Transportation":
      return data.transportation;
    case "Utility":
      return data.utility;
    case "Legal Resources":
      return data.legal;
    case "Clothing":
      return data.clothing;
    case "Technology Support":
      return data.technology;
    case "Job Offerings":
      return data.job;
    case "Community Activities":
      return data.community;
    default:
      alert(`Sorry, we cannot find ${title}.`);
  }
};
