import React from "react";
import Button from "../Button/Button";
import Input from "./Input";
import useStyles from "./Form.styles";
import { useValidation } from "./useValidation";
import AnalyticsService from "../../analyticsService";

export type IContactForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  message: string;
};

const Form = () => {
  const classes = useStyles();
  const { validateInput, validateForm, errors, formatPhoneNumber } =
    useValidation();
  const [inputValues, setInputValues] = React.useState<IContactForm>({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const analytics = new AnalyticsService();

  const sendEmail = (inputValues: IContactForm) => {
    /* eslint-disable no-console */
    //console.log("submission", JSON.stringify(inputValues, null, 2));

    let mailto = "mailto:info@detroitphoenixcenter.org";

    mailto += "?subject=" + encodeURIComponent("Inquiry from App");

    let body = "";
    let key: keyof IContactForm;
    for (key in inputValues) {
      if (body.length > 0) body += "\n";
      body += key + ": " + inputValues[key];
    }

    mailto += "&body=" + encodeURIComponent(body);

    window.open(mailto);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value =
      e.target.name === "phoneNumber"
        ? formatPhoneNumber(e.target.value)
        : e.target.value;
    setInputValues({
      ...inputValues,
      [e.target.name]: value,
    });
  };

  const resetForm = () => {
    setInputValues({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      message: "",
    });
  };

  const handleSubmit = () => {
    if (validateForm(inputValues)) {
      sendEmail(inputValues);
      resetForm();
      analytics.logButtonTap("submit");
    } else {
      /* eslint-disable no-console */
      console.log("errors", errors);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        id="first-name"
        label="First Name"
        name="firstName"
        onChange={handleInputChange}
        onBlur={(e) => validateInput(e)}
        required
        value={inputValues.firstName}
        placeholder="John"
        error={errors.firstName}
        helperText="First name is required"
      />
      <Input
        id="last-name"
        label="Last Name"
        required
        name="lastName"
        onChange={handleInputChange}
        onBlur={(e) => validateInput(e)}
        value={inputValues.lastName}
        placeholder="Smith"
        error={errors.lastName}
        helperText="Last name is required"
      />
      <Input
        id="phone-number"
        label="Phone Number"
        name="phoneNumber"
        onChange={handleInputChange}
        onBlur={(e) => validateInput(e)}
        value={inputValues.phoneNumber}
        placeholder="(000)-000-0000"
        error={errors.phoneNumber}
        helperText="Phone number invalid"
      />
      <Input
        id="email"
        label="Email"
        name="email"
        required
        onChange={handleInputChange}
        onBlur={(e) => validateInput(e)}
        value={inputValues.email}
        placeholder="johnsmith@mailserver.com"
        error={errors.email}
        helperText={
          inputValues.email.trim() === ""
            ? "Email is required"
            : "Please enter a valid email"
        }
      />
      <Input
        textarea
        id="message"
        label="Message"
        name="message"
        onChange={handleInputChange}
        onBlur={(e) => validateInput(e)}
        value={inputValues.message}
        placeholder="Hello DPC"
        error={false}
      />
      <div className={classes.btnContainer}>
        <Button orangePrimary onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default Form;
