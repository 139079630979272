import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  offlineAlert: {
    zIndex: 1,
    width: "100%",
    margin: theme.spacing(0),
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    boxSizing: "border-box",
    backgroundColor: "#DA1F26",
    "&.MuiTypography-root": {
      color: "#F7F8F7",
      textAlign: "center",
      lineHeight: "20px",
    },
  },
}));

export default useStyles;
