export const phoneNumber = "3134820916";

export const phoneNumberFormatted =
  phoneNumber.substring(0, 3) +
  "-" +
  phoneNumber.substring(3, 6) +
  "-" +
  phoneNumber.substring(6);

export const address = [
  "YouthVille Detroit",
  "7375 Woodward Ave, Suite 1520",
  "Detroit, MI 48202",
];
