import { NavLink as Link } from "react-router-dom";
import useStyles from "./NavLink.styles";

type IProps = {
  href: string;
  children: string;
  onClick?: () => void;
};

const NavLink = (props: IProps) => {
  const classes = useStyles();
  return (
    <Link
      className={classes.navLink}
      onClick={props.onClick}
      to={props.href}
      style={({ isActive }) =>
        isActive ? { color: "#f27b1f" } : { color: "#222222" }
      }
    >
      {props.children}
    </Link>
  );
};

export default NavLink;
