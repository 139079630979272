import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    borderBottom: "1px solid #4B5563",
    paddingBottom: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(1.5),
    },
    "& .MuiTypography-body2:first-child": {
      fontWeight: 600,
    },
    "& a": {
      color: "#0C8DFF",
      "&:hover": {
        color: "#0c51ff",
      },
    },
  },
  label: {
    fontWeight: 600,
    marginRight: theme.spacing(0.75),
  },
}));

export default useStyles;
