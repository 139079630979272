import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  footerContainer: {
    backgroundColor: "#F7F8F7",
    boxSizing: "border-box",
    borderTop: "1px solid #4B5563",
    color: "#222222",
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    textAlign: "left",
    width: "100vw",
    height: "286px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: `${theme.spacing(5)}`,
      paddingRight: `${theme.spacing(5)}`,
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: `${theme.spacing(6)}`,
      paddingRight: `${theme.spacing(6)}`,
    },
    "& h1": {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 14,
      fontWeight: 600,
      lineHeight: "20px",
      marginBottom: 8,
      textAlign: "left",
    },
    "& p": {
      fontFamily: "Poppins, sans-serif",
      fontSize: 12,
      lineHeight: "20px",
    },
  },
  addressName: {
    "&.MuiTypography-root": {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
    },
  },
  addressBody: {
    "&.MuiTypography-root": {
      fontWeight: 400,
    },
  },
  dayOfWeek: {
    fontWeight: 500,
  },
  finePrint: {
    color: "#4B5563",
    fontSize: 12,
    "& a": {
      color: "#0C8DFF",
      "&:hover": {
        color: "#0c51ff",
      },
      fontWeight: 400,
    },
    "& p": {
      marginTop: `${theme.spacing(0.5)}`,
      fontWeight: 500,
    },
  },
  hotline: {
    "&.MuiTypography-root": {
      fontWeight: 500,
      margin: `${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(
        1.25
      )} ${theme.spacing(0)}`,
    },
    "& a": {
      color: "#0C8DFF",
      "&:hover": {
        color: "#0c51ff",
      },
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    marginBottom: `${theme.spacing(3)}`,
    width: `${theme.spacing(15)}`,
    "& a": {
      height: `${theme.spacing(3)}`,
    },
  },
  facebookIcon: {
    marginRight: 21,
    "& svg": { padding: `${theme.spacing(0.25)} ${theme.spacing(0.875)}` },
  },
  instaIcon: {
    marginRight: 27,
    "& svg": { padding: `${theme.spacing(0.25)}` },
  },
  linkedinIcon: {
    marginRight: 27,
    "& svg": { padding: `${theme.spacing(0.125)}` },
  },
  podcastIcon: {
    "& svg": { padding: `${theme.spacing(0.25)}` },
  },
}));

export default useStyles;
