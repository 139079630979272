import React from "react";
import { AppBar, Toolbar, IconButton, Typography } from "@mui/material";
import { ReactComponent as Hamburger } from "../../assets/icons/ic-hamburger.svg";
import Logo from "../../assets/images/Logo.png";
import Navigation from "../Navigation/Navigation";
import useStyles from "./Navbar.styles";
import { NavLink } from "react-router-dom";
import AnalyticsService from "../../analyticsService";

const Navbar = () => {
  const classes = useStyles();
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);
  const anayltics = new AnalyticsService();
  const onClickHamburger = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    anayltics.logButtonTap("menu");
  };
  const onClickClose = () => setMobileMenuOpen(false);

  return (
    <div className={classes.navbarContainer}>
      <AppBar>
        <Toolbar className={classes.toolbar}>
          <div className={classes.navLogoTextContainer}>
            <NavLink to="/" title="YouthUp by Detroit Phoenix Center">
              <img src={Logo} className={classes.logo} />
            </NavLink>
            <Typography variant="body2" className={classes.navbarText}>
              No matter the situation, you are not walking alone.
            </Typography>
          </div>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => onClickHamburger()}
          >
            <Hamburger />
          </IconButton>
          <Navigation />
        </Toolbar>
      </AppBar>
      <Navigation
        sm
        onClickClose={() => onClickClose()}
        mobileMenuOpen={mobileMenuOpen}
      />
    </div>
  );
};

export default Navbar;
