import React from "react";
import { IContactForm } from "./Form";
import { TLDs } from "../../utils/email-valid-tdl";

type IValidation = {
  firstName: boolean | undefined;
  lastName: boolean | undefined;
  phoneNumber: boolean | undefined;
  email: boolean | undefined;
};

export const useValidation = () => {
  const [errors, setErrors] = React.useState<IValidation>({
    firstName: undefined,
    lastName: undefined,
    phoneNumber: undefined,
    email: undefined,
  });

  const validateInput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.name === "firstName") {
      const isFirstNameError = validateFirstName(e.target.value);
      setErrors((prevState) => {
        return { ...prevState, firstName: isFirstNameError };
      });
    }
    if (e.target.name === "lastName") {
      const isLastNameError = validateLastName(e.target.value);
      setErrors((prevState) => {
        return { ...prevState, lastName: isLastNameError };
      });
    }
    if (e.target.name === "phoneNumber") {
      const isPhoneNumError = validatePhoneNum(e.target.value);
      setErrors((prevState) => {
        return { ...prevState, phoneNumber: isPhoneNumError };
      });
    }
    if (e.target.name === "email") {
      const isEmailError = validateEmail(e.target.value);
      setErrors((prevState) => {
        return { ...prevState, email: isEmailError };
      });
    }
  };

  const formatPhoneNumber = (phoneNum: string) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!phoneNum) return phoneNum;

    // clean the input for any non-digit values.
    const phoneNumber = phoneNum.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less than four digits
    // this is to avoid weird behavior that occurs if you  format the area code too early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less than 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    // eslint-disable-next-line prettier/prettier
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const validateFirstName = (name: string) => {
    return name.trim() === "";
  };

  // not sure if we ever need to do separate validation for first and last names,
  // but keeping this for now just in case
  const validateLastName = (name: string) => {
    return name.trim() === "";
  };

  const validatePhoneNum = (phoneNum: string) => {
    // No phone number entered (phoneNum.length == 0) is valid
    // If user started to type a phone number but didn't finish it, then invalid
    return phoneNum.length > 0 && phoneNum.length < 14;
  };

  const validateEmail = (email: string) => {
    // If empty, error
    if (email.trim() === "") return true;

    const regex = new RegExp(
      "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.[a-zA-Z0-9-]"
    );
    //split the email after the @ symbol(example: [gmail, com])
    const splitEmail = email.split("@")[1]?.split(".");
    //take the last index of the array and transform it to uppercase(example: COM)
    const domainExt = splitEmail
      ? splitEmail[splitEmail.length - 1].toUpperCase()
      : undefined;
    //compare the domainExtention to the list of valid extensions
    const validExt = domainExt
      ? !!TLDs.find((t: string) => t === domainExt)
      : false;
    //check that the email matches both the regex and the valid extentions
    const match = regex.test(email.trim()) && validExt;
    //if match is true then error should be false
    return !match;
  };

  const validateForm = (inputValues: IContactForm) => {
    // check for errors
    const newErrors: IValidation = {
      firstName: validateFirstName(inputValues.firstName),
      lastName: validateLastName(inputValues.lastName),
      phoneNumber: validatePhoneNum(inputValues.phoneNumber),
      email: validateEmail(inputValues.email),
    };

    // and remember them in state
    setErrors(newErrors);

    const noErrors =
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.phoneNumber &&
      !newErrors.email;

    return noErrors;
  };

  return {
    errors,
    validateInput,
    formatPhoneNumber,
    validatePhoneNum,
    validateForm,
  };
};
