import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";
import ContactBackgroundImage from "../../assets/images/Contact-Background-Image.jpg";

const useStyles = makeStyles<Theme>((theme) => ({
  contactContainer: {
    backgroundImage: `url(${ContactBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "center",
    },
    "& #contentsContainer": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("md")]: {
        paddingLeft: "134px",
        paddingRight: "134px",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
      },
    },
    "& #formDescription": {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(6),
      },
      "& .bold": {
        fontWeight: 600,
      },
      "& a": {
        color: "#0C8DFF",
        "&:hover": {
          color: "#0c51ff",
        },
      },
    },
  },
}));

export default useStyles;
