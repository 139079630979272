export const forceHttps = (url: string) => {
  const beginsWithHttps = new RegExp("^https://");
  const beginsWithHttp = new RegExp("^http://");
  if (url.match(beginsWithHttps)) {
    return url;
  } else if (url.match(beginsWithHttp)) {
    return url.replace(beginsWithHttp, "https://");
  } else {
    return "//" + url;
  }
};
