import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  modal: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(34, 34, 34, 0.5)",
    },
    "& .MuiBox-root": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      boxShadow: "0px 24px 40px rgba(31, 41, 55, 0.15)",
      padding: `${theme.spacing(1.5)} ${theme.spacing(5)}`,
      borderRadius: "8px",
      boxSizing: "border-box",
      width: "343px",
      [theme.breakpoints.up("md")]: {
        width: "560px",
      },
      [theme.breakpoints.up("lg")]: {
        width: "744px",
      },
    },
    "& .MuiTypography-h1": {
      textAlign: "left",
      fontSize: "24px",
      lineHeight: "24px",
      margin: 0,
      [theme.breakpoints.up("md")]: {
        fontSize: "26px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "28px",
      },
    },
    "& p.MuiTypography-body2": {
      fontWeight: 500,
      lineHeight: "20px",
      margin: 0,
    },
    "& ul": {
      margin: "12px 0",
      paddingInlineStart: 24,
      [theme.breakpoints.up("md")]: {
        paddingInlineStart: 35,
      },
      "& li.MuiTypography-body2": {
        fontWeight: 400,
        lineHeight: "20px",
        [theme.breakpoints.up("lg")]: {
          lineHeight: "22px",
        },
      },
    },
  },
  btnContainer: {
    marginTop: theme.spacing(3),
  },
  closeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "12px -16px 8px 0px",
    "& .MuiIconButton-root": {
      width: "24px",
      height: "24px",
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      "& svg": {
        width: "24px",
        height: "24px",
      },
    },
  },
  dropInModal: {
    "& .MuiTypography-h1": {
      marginBottom: theme.spacing(1.5),
    },
    "& .MuiTypography-root.MuiTypography-body2": {
      "&:not(:last-of-type)": {
        marginBottom: theme.spacing(1.5),
        fontWeight: 400,
      },
    },
    "& .MuiBox-root": {
      paddingBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
