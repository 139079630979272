import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 6,
    "& button.MuiButton-root": {
      width: "50%",
      marginBottom: 0,
    },
  },
});

export default useStyles;
