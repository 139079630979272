import analytics from "./firebase";
import { logEvent } from "firebase/analytics";

class AnalyticsService {
  logScreenName(screenName: string) {
    this.logPageEvent(screenName, "page");
  }

  logModalName(screenName: string) {
    this.logPageEvent(screenName, "modal");
  }

  private logPageEvent(screenName: string, screenClass: string) {
    logEvent(analytics, "screen_view", {
      firebase_screen: screenName,
      firebase_screen_class: screenClass,
    });
  }

  logButtonTap(stringId: string) {
    logEvent(analytics, "select_content", {
      content_type: "button",
      item_id: stringId,
    });
  }

  logLinkTap(stringId: string) {
    logEvent(analytics, "select_content", {
      content_type: "link",
      item_id: stringId,
    });
  }
}

export default AnalyticsService;
