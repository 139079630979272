import { Typography } from "@mui/material";
import { useState, useEffect } from "react";
import useStyles from "./OfflineAlert.styles";

const OfflineAlert = () => {
  const [isOnline, setOnlineStatus] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    const setFromEvent = function (event: Event) {
      if (event.type === "online") {
        setOnlineStatus(true);
      } else if (event.type === "offline") {
        setOnlineStatus(false);
      }
    };

    window.addEventListener("online", setFromEvent);
    window.addEventListener("offline", setFromEvent);

    return () => {
      window.removeEventListener("online", setFromEvent);
      window.removeEventListener("offline", setFromEvent);
    };
  });

  return !isOnline ? (
    <>
      <Typography variant="body2" className={classes.offlineAlert}>
        Offline mode enabled. Some features may not available.
      </Typography>
    </>
  ) : null;
};

export default OfflineAlert;
