import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    margin: `${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(4.5)}`,
    [theme.breakpoints.up("md")]: {
      margin: `${theme.spacing(5)} ${theme.spacing(0)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.up("lg")]: {
      margin: `${theme.spacing(6)} ${theme.spacing(0)} ${theme.spacing(4.5)}`,
    },
  },
  title: {
    "&.MuiTypography-root": {
      marginBottom: theme.spacing(3),
      fontSize: 24,
      [theme.breakpoints.up("md")]: {
        fontSize: 28,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
      },
    },
  },
  section: {
    "& .MuiTypography-body1": {
      marginBottom: theme.spacing(1.5),
    },
    "&:first-of-type": {
      marginBottom: theme.spacing(4.5),
    },
  },
}));

export default useStyles;
