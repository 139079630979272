import React, { useEffect } from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/ic-close.svg";
import Button from "../Button/Button";
import useStyles from "./Modal.styles";
import { useNavigate } from "react-router-dom";
import { phoneNumber } from "../../utils/global-values";
import AnalyticsService from "../../analyticsService";

type IProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
};

const EmergencyModal = (props: IProps) => {
  const [openAssessment, setOpenAssessment] = React.useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const analytics = new AnalyticsService();

  const onClickClose = () => {
    setOpenAssessment(false);
    analytics.logButtonTap("no");
    props.setOpen(false);
  };

  const onClickYes = () => {
    navigate("/emergency");
    analytics.logButtonTap("yes");
    props.setOpen(false);
  };

  const onClickUnsure = () => {
    setOpenAssessment(true);
    analytics.logButtonTap("unsure");
  };

  const onCallDPC = () => {
    window.open(`tel:${phoneNumber}`, "_self");
    analytics.logButtonTap("call DPC");
  };

  const onClickScheduleService = () => {
    navigate("/");
    props.setOpen(false);
    analytics.logButtonTap("schedule a service");
  };

  const CrisisQuestion = () => {
    useEffect(() => {
      analytics.logModalName("Housing Crisis Modal");
    });
    return (
      <>
        <Typography variant="h1" id="modal-title">
          Are you 13-24 years old and experiencing a housing crisis?
        </Typography>
        <div className={classes.btnContainer}>
          <Button onClick={() => onClickYes()} orangePrimary>
            Yes
          </Button>
          <Button onClick={() => onClickClose()} orangeSecondary>
            No
          </Button>
          <Button onClick={() => onClickUnsure()} orangeSecondary>
            Unsure
          </Button>
        </div>
      </>
    );
  };

  const EmergencyAssessment = () => {
    useEffect(() => {
      analytics.logModalName("Services Assessment");
    });
    return (
      <>
        <Typography variant="h1" id="modal-title">
          Emergency Services Short Assessment
        </Typography>
        <ul>
          <Typography variant="body2" component="li">
            Are you living in a shelter?
          </Typography>
          <Typography variant="body2" component="li">
            Are you sleeping on a friend&#39;s or family member&#39;s couch?
          </Typography>
          <Typography variant="body2" component="li">
            Do you not have access to running water in your home?
          </Typography>
          <Typography variant="body2" component="li">
            Are you thinking about running away?
          </Typography>
          <Typography variant="body2" component="li">
            Were you put out of your home?
          </Typography>
        </ul>
        <Typography variant="body2" id="modal-description">
          If you answered &#39;yes&#39; to any of the above questions, give us a
          call or book a service.
        </Typography>
        <div className={classes.btnContainer}>
          <Button onClick={() => onCallDPC()} orangePrimary>
            Call DPC
          </Button>
          <Button onClick={() => onClickScheduleService()} orangeSecondary>
            Schedule a Service
          </Button>
        </div>
      </>
    );
  };

  return (
    <Modal
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={props.open}
      onClose={() => onClickClose()}
    >
      <Box>
        <div className={classes.closeBtnContainer}>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => onClickClose()}
          >
            <Close />
          </IconButton>
        </div>
        {openAssessment ? <EmergencyAssessment /> : <CrisisQuestion />}
      </Box>
    </Modal>
  );
};

export default EmergencyModal;
