import { makeStyles } from "@mui/styles";
import HeroImage from "../../assets/images/Hero-Image.png";
import ServicesBackgroundImage from "../../assets/images/Services-Background-Image.png";
import ContactBoxBackground from "../../assets/images/contact-box-background.png";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  homepageContainer: {
    "&.MuiContainer-root": {
      display: "flex",
      flexDirection: "column",
      maxWidth: "none",
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  headerTextContainer: {
    height: 193,
    backgroundImage: "url(" + HeroImage + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up("md")]: {
      height: 395,
    },
    [theme.breakpoints.up("lg")]: {
      height: 527,
    },
    "& h1": {
      marginTop: 28,
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(6.25),
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: theme.spacing(11),
      },
    },
  },
  descriptionBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "white",
    color: "black",
    padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6),
    },
  },
  descriptionText: {
    "&.MuiTypography-body1": {
      lineHeight: "32px",
    },
  },
  servicesContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${ServicesBackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 474,
    [theme.breakpoints.up("md")]: {
      height: 538,
    },
    [theme.breakpoints.up("lg")]: {
      height: 578,
    },
  },
  scheduleServicesWrapper: {
    width: 327,
    margin: `${theme.spacing(3)} auto ${theme.spacing(0)} auto`,
    [theme.breakpoints.up("md")]: {
      width: 500,
      margin: `${theme.spacing(5)} auto ${theme.spacing(0)} auto`,
    },
    [theme.breakpoints.up("lg")]: {
      width: 756,
      margin: `${theme.spacing(6)} auto ${theme.spacing(0)} auto`,
    },
  },
  dropInServicesWrapper: {
    width: 327,
    margin: `${theme.spacing(4.5)} auto ${theme.spacing(0)} auto`,
    [theme.breakpoints.up("md")]: {
      width: 500,
      margin: `${theme.spacing(8.5)} auto ${theme.spacing(0)} auto`,
    },
    [theme.breakpoints.up("lg")]: {
      width: 756,
      margin: `${theme.spacing(10.5)} auto ${theme.spacing(0)} auto`,
    },
  },
  servicesText: {
    zIndex: 1,
    display: "flex",
    "&.MuiTypography-h2": {
      marginBottom: theme.spacing(3),
      fontSize: 24,
      [theme.breakpoints.up("md")]: {
        fontSize: 28,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
      },
    },
  },
  contactBoxContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundImage: `url(${ContactBoxBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 136,
    [theme.breakpoints.up("md")]: {
      height: 158,
    },
    [theme.breakpoints.up("lg")]: {
      height: 179,
    },
  },
  contactBoxTextWrapper: {
    width: 327,
    margin: `${theme.spacing(3)} auto ${theme.spacing(0)} auto`,
    [theme.breakpoints.up("md")]: {
      width: 500,
      margin: `${theme.spacing(5)} auto ${theme.spacing(0)} auto`,
    },
    [theme.breakpoints.up("lg")]: {
      width: 756,
      margin: `${theme.spacing(6)} auto ${theme.spacing(0)} auto`,
    },
  },
  contactBoxNotSureTypography: {
    "&.MuiTypography-h2": {
      fontSize: 24,
      marginBottom: theme.spacing(1.25),
      color: "#F7F8F7",
      [theme.breakpoints.up("md")]: {
        fontSize: 28,
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: 36,
      },
    },
  },
  contactNumberTypography: {
    "&.MuiTypography-body1": {
      color: "#F7F8F7",
    },
  },
  telephoneLink: {
    color: "#F7F8F7",
  },
}));

export default useStyles;
