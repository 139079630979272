import { Button as Btn, Link } from "@mui/material";
import useStyles from "./Button.styles";

type IProps = {
  onClick?: () => void;
  children: string | Element;
  blue?: boolean;
  orangePrimary?: boolean;
  orangeSecondary?: boolean;
  link?: boolean;
  href?: string;
};

const Button = (props: IProps) => {
  const classes = useStyles();
  return props.link ? (
    <Link
      className={
        props.blue
          ? `${classes.button} ${classes.blue}`
          : props.orangePrimary
          ? `${classes.button} ${classes.orangePrimary}`
          : props.orangeSecondary
          ? `${classes.button} ${classes.orangeSecondary}`
          : classes.button
      }
      onClick={props.onClick}
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      {props.children}
    </Link>
  ) : (
    <Btn
      className={
        props.blue
          ? `${classes.button} ${classes.blue}`
          : props.orangePrimary
          ? `${classes.button} ${classes.orangePrimary}`
          : props.orangeSecondary
          ? `${classes.button} ${classes.orangeSecondary}`
          : classes.button
      }
      onClick={props.onClick}
    >
      {props.children}
    </Btn>
  );
};

export default Button;
