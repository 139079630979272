const breakpoints = {
  values: {
    xs: 320,
    sm: 375,
    md: 768,
    lg: 1024,
    xl: 1200,
    xxl: 1440,
  },
};

export default breakpoints;
