import React from "react";
import "./App.css";
import theme from "./style/muiTheme";
import { Box, Container, ThemeProvider } from "@mui/material";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/NavBar/Navbar";
import Components from "./components/Components";
import KnowYourRights from "./pages/KnowYourRights/KnowYourRights";
import EmergencyServicesPage from "./pages/EmergencyServicesPage/EmergencyServicesPage";
import Faq from "./pages/Faq/Faq";
import NotFound from "./pages/NotFound/NotFound";
import EmergencyModal from "./components/Modals/EmergencyModal";
import Home from "./pages/Home/Home";
import QuickAssistancePage from "./pages/QuickAssistancePage/QuickAssistancePage";
import Contact from "./pages/Contact/Contact";
import OfflineAlert from "./components/OfflineAlert/OfflineAlert";

function App() {
  const [openModal, setOpenModal] = React.useState<boolean>(true);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <EmergencyModal open={openModal} setOpen={setOpenModal} />
          <Navbar />
          <OfflineAlert />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route
              element={
                <Container className="content">
                  <Outlet />
                </Container>
              }
            >
              <Route path="emergency" element={<EmergencyServicesPage />} />
              <Route path="assistance" element={<QuickAssistancePage />} />
              <Route path="faqs" element={<Faq />} />
              <Route path="rights" element={<KnowYourRights />} />
              <Route path="components" element={<Components />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
          <Box className="footer">
            <Footer />
          </Box>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
