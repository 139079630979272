import useStyles from "./Home.styles";
import { Container, Typography } from "@mui/material";
import Button from "../../components/Button/Button";
import { useMemo, useState } from "react";
import DropInServicesModal from "../../components/Modals/DropInServicesModal";
import { phoneNumber, phoneNumberFormatted } from "../../utils/global-values";
import Copy from "../../utils/data/copy";
import AnalyticsService from "../../analyticsService";
import { Helmet } from "react-helmet";

function HomePage() {
  const classes = useStyles();
  const [openFoodModal, setOpenFoodModal] = useState(false);
  const [openComputerModal, setOpenComputerModal] = useState(false);
  const analytics = useMemo(() => {
    return new AnalyticsService();
  }, []);

  return (
    <>
      <Helmet>
        <title>YouthUp</title>
      </Helmet>
      <Container
        style={{ maxWidth: "none", padding: 0 }}
        className={classes.homepageContainer}
      >
        <div className={classes.headerTextContainer}>
          <Typography variant="h1" component="h1">
            YouthUp
          </Typography>
        </div>
        <div className={classes.descriptionBox}>
          <Typography className={classes.descriptionText}>
            Detroit Phoenix Center (DPC) is a nonprofit organization that
            responds to the wrap around needs of youth & young adults in
            Detroit. DPC services & programs are focused on: basic needs &
            emergency shelter, mental health & wellness, youth leadership &
            activism, and education & employment.
          </Typography>
        </div>
        <div className={classes.servicesContainer}>
          <div className={classes.scheduleServicesWrapper}>
            <Typography
              className={classes.servicesText}
              variant="h2"
              component="h2"
            >
              Schedule a Service
            </Typography>
            <Button
              blue
              link
              href="https://calendly.com/detroitphoenixcenter/laundry"
            >
              Laundry
            </Button>
            <Button
              blue
              link
              href="https://calendly.com/detroitphoenixcenter/shower"
            >
              Shower
            </Button>
            <Button
              blue
              link
              href="https://calendly.com/detroitphoenixcenter/case-management-appointment"
            >
              Case Management
            </Button>
          </div>
          <div className={classes.dropInServicesWrapper}>
            <Typography
              className={classes.servicesText}
              variant="h2"
              component="h2"
            >
              Drop-In Services
            </Typography>
            <Button
              blue
              onClick={() => {
                setOpenFoodModal(true);
                analytics.logButtonTap("food pantry");
              }}
            >
              Food Pantry
            </Button>
            <Button
              blue
              onClick={() => {
                setOpenComputerModal(true);
                analytics.logButtonTap("computer lab");
              }}
            >
              Computer Lab
            </Button>
          </div>
        </div>

        <div className={classes.contactBoxContainer}>
          <div className={classes.contactBoxTextWrapper}>
            <Typography
              className={classes.contactBoxNotSureTypography}
              variant="h2"
              component="h2"
            >
              Not Sure?
            </Typography>
            <Typography className={classes.contactNumberTypography}>
              Give us a call at{" "}
              <a
                className={classes.telephoneLink}
                onClick={() => analytics.logLinkTap("call DPC")}
                href={"tel:1" + phoneNumber}
              >
                {phoneNumberFormatted}
              </a>{" "}
              if you need any help.
            </Typography>
          </div>
        </div>
      </Container>
      <DropInServicesModal
        open={openComputerModal}
        setOpen={setOpenComputerModal}
      >
        <Typography variant="h1" id="modal-title">
          Computer Lab
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.computerLab.p1}
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.computerLab.p2}
        </Typography>
      </DropInServicesModal>
      <DropInServicesModal open={openFoodModal} setOpen={setOpenFoodModal}>
        <Typography variant="h1" id="modal-title">
          Food Pantry
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.foodPantry.p1}
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.foodPantry.p2}
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.foodPantry.p3}
        </Typography>
        <Typography variant="body2">
          {Copy.DropInServices.foodPantry.p4}
        </Typography>
      </DropInServicesModal>
    </>
  );
}

export default HomePage;
