import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inputWrapper: {
    "&.MuiFormControl-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      flex: 1,
      "& .MuiInputLabel-root": {
        color: "#222222",
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 16,
        lineHeight: "20px",
        margin: "6px 0",
        position: "unset",
        transition: "unset",
        transform: "none",
      },
      "& .Mui-error": {
        color: "#d32f2f",
        "& + .MuiInput-root input, + .MuiInput-root textarea": {
          border: "1px solid #d32f2f",
        },
      },
      "& .MuiInput-root": {
        width: "100%",
        "& input, & textarea": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "12px 16px",
          marginBottom: "6px",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "normal",
          fontSize: 14,
          lineHeight: "20px",
          background: "#FFFFFF",
          border: "1px solid #969696",
          boxSizing: "border-box",
          borderRadius: 4,
          width: "100%",
        },
        "& input": {
          height: 45,
        },
        "& textarea": {
          height: 117,
        },
      },
    },
  },
  errorInput: {
    "& .MuiFormControl-root .MuiInput-input": {
      border: "1px solid #d32f2f",
    },
  },
});

export default useStyles;
