import { Typography } from "@mui/material";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import { address } from "../../utils/global-values";
import useStyles from "./Faq.styles";
import { Helmet } from "react-helmet";

type IQAndA = {
  q: string;
  a: string | JSX.Element;
};

type IAnswer = {
  children: JSX.Element;
};

const Answer = (props: IAnswer) => {
  return <>{props.children}</>;
};

const Faq = () => {
  const classes = useStyles();
  const content: Array<IQAndA> = [
    {
      q: "Who does Detroit Phoenix Center serve?",
      a: (
        <Answer>
          <>
            <Typography variant="body2">
              We work for youth ages, 13-24 living with or without their parent
              or guardian who are:
            </Typography>
            <ul className={classes.list}>
              <Typography variant="body2" component="li">
                Unable to resolve conflict with parents or guardian
              </Typography>
              <Typography variant="body2" component="li">
                Living in an unsafe or unstable environment at home
              </Typography>
              <Typography variant="body2" component="li">
                Couch surfing or staying temporarily with friends or family
              </Typography>
              <Typography variant="body2" component="li">
                Sleeping unsheltered, outside, in a car, or in other places not
                meant for housing
              </Typography>
            </ul>
          </>
        </Answer>
      ),
    },
    {
      q: "What services do you provide?",
      a: "We provide a daily drop-in center where youth can access showers, washer/dryer, food pantry, computer lab, day beds, hygiene products, life skills classes, etc. an afterschool enrichment program, youth leadership programming, identification support, emergency crisis housing, and a scholarship fund.",
    },
    {
      q: "What service does the computer lab provide?",
      a: "The Computer Lab is available for online school courses, homework, job searching,  resume development, art projects, and networking. There is printing and scanning assistance available as well!",
    },
    {
      q: "Where are you located?",
      a: (
        <Answer>
          <>
            <address className={classes.address}>
              <Typography variant={"body2"}>
                <span className="address-heading">{address[0]}</span>
                <br />
                {address[1]}
                <br />
                {address[2]}
              </Typography>
            </address>
          </>
        </Answer>
      ),
    },
    {
      q: "What ages do you serve?",
      a: "13-24",
    },
    {
      q: "Do I have to pay for support?",
      a: "No, our services are completely FREE.",
    },
    {
      q: "How do I stay updated on events, programs, and services?",
      a: "You can follow us on our social media, subscribe to our newsletter, use our app, and frequently visit our website.",
    },
    {
      q: "Do I need to make an appointment before entering the drop-in center?",
      a: "No, you do not need to make an appointment beforehand.",
    },
    {
      q: "What is provided at the drop-in?",
      a: (
        <Answer>
          <>
            <Typography variant="body2">
              Hot meals, showers, bean bags for rest, washer/dryer, telephone
              access, lockers, computer lab, access to our pantry which has
              nonperishable food items, hygiene products, casual clothes, and
              household items.
            </Typography>
            <Typography variant="body2">
              There is also a game room to have fun, mental and physical health
              support, and connections to other resources in the community for
              housing, education, jobs, and more.
            </Typography>
          </>
        </Answer>
      ),
    },
    {
      q: "Can I just show up?",
      a: (
        <Answer>
          <>
            <Typography variant="body2">
              Yes, during the following Drop-In hours:
            </Typography>
            <ul className={classes.dropInHours}>
              <Typography variant="body2" component="li">
                <span className="bold">Monday: </span>
                11:00 am - 5:00 pm (ages 13-17)
              </Typography>
              <Typography variant="body2" component="li">
                <span className="bold">Wednesday: </span>
                11:00 am - 5:00 pm (ages 13-17)
              </Typography>
              <Typography variant="body2" component="li">
                <span className="bold">Friday: </span>
                11:00 am - 5:00 pm (ages 18-24)
              </Typography>
              <Typography variant="body2" component="li">
                <span className="bold">Sunday: </span>
                10:00 am - 1:00 pm (ages 18-24)
              </Typography>
            </ul>
          </>
        </Answer>
      ),
    },
    {
      q: "What kind of programs do you have?",
      a: "We have after school tutoring programs, youth development workshops, mental health awareness resources, quality community events, guest speakers such as community leaders, activists, artists, and policymakers, collaborations with other organizations and Detroit establishments, and internal events such as game nights, and movie nights for our SOAR scholars.",
    },
  ];

  return (
    <>
      <Helmet>
        <title>YouthUp - FAQs</title>
      </Helmet>
      <div>
        <PageTitleBanner title="FAQs" />
        <div className={classes.contentContainer}>
          {content.map((c, key) => (
            <div className={classes.layout} key={key}>
              <Typography className={classes.question} variant={"body2"}>
                {c.q}
              </Typography>
              {typeof c.a === "string" && (
                <Typography variant={"body2"}>{c.a}</Typography>
              )}
              {typeof c.a !== "string" && <div>{c.a}</div>}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Faq;
