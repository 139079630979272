import { phoneNumberFormatted } from "../global-values";

const Copy = {
  DropInServices: {
    foodPantry: {
      p1: "We have nonperishable food items that vary based on the donations we receive. We also have hygiene essentials, feminine care products, clothes, household items, and school supplies.",
      p2: "Pantry items may include mac & cheese, beef jerky, noodles, apple sauce, canned fruits/vegetables, beans, soups, cereal bars, rice, instant mashed potatoes, and oatmeal.",
      p3: "Hygiene items may include shampoo, conditioner, body wash, deodorant, razors, feminine care necessities, shaving cream, hair gel, dry shampoo, hair accessories, toothbrushes, toothpaste, and mouth wash.",
      p4: "An appointment is not required to take advantage of the Food Pantry during our normal hours of operation.",
    },
    computerLab: {
      p1: "Our Computer Lab at YouthVille Detroit is available for online school courses, homework, job searching,  resume development, art projects, and networking. There is printing and scanning assistance available as well!",
      p2: "An appointment is not required to utilize the Computer Lab during our normal hours of operation.",
    },
  },
  EmergencyServices: {
    section1: {
      title: "911",
      body: "Call 911 for immediate emergency assistance. Emergency situations where law enforcement, firefighters, or medical help is needed right away. 911 should also be called when you or the person you care for feels at risk or in danger.",
      phoneNumber: "9-1-1",
    },
    section2: {
      title: "211",
      body: "211 is a hotline used for those in urgent need of help with food, housing, or paying bills.",
      phoneNumber: "2-1-1",
    },
    section3: {
      title: "Detroit Wayne Integrated Health Network",
      body: "Detroit Wayne Integrated Health Network (DWIHN) offers Mental Health crisis help 24 hours a day, 7 days a week. DWIHN supports and serves individuals with serious mental illness, autism, intellectual and developmental disabilities, substance use disorder, and children with emotional disturbance.",
      phoneNumber: "1-(800) 241-4949",
      link: "https://www.dwihn.org",
    },
    section4: {
      title: "Detroit Phoenix Center",
      subTitle: "-NOT AN EMERGENCY SHELTER-",
      body: "Detroit Phoenix Center (DPC) provides critical resources and wrap-around support. DPC is a drop-in center that serves as a one-stop for showers, laundry services, food pantry, computer lab, day beds, hygiene essentials, after-school enrichment, identification support, and crisis relief. DPC does not offer overnight shelter assistance.",
      phoneNumber: phoneNumberFormatted,
      link: "https://www.detroitphoenixcenter.org",
    },
    section5: {
      title: "CAM Detroit",
      body: "The Coordinated Assessment Model (CAM) Detroit provides those experiencing homelessness with access to shelter and housing resources. Any individual, youth, or family in need of a safe place to sleep can contact CAM for assistance. Through a standard assessment process, CAM will prioritize housing resources and provides referrals as they become available.",
      link: "https://www.camdetroit.org",
      phoneNumber: "(313) 305-0311",
    },
  },
  KnowYourRights: {
    section1: {
      title: "ACLU",
      body: "Everyone has basic rights under the U.S. Constitution and civil rights laws. Learn more here about what your rights are, how to exercise them, and what to do when your rights are violated.",
      link: "https://www.aclu.org/know-your-rights/",
    },
    section2: {
      title: "Youth.gov",
      body: "Youth.gov is the U.S. government website that helps you create, maintain, and strengthen effective youth programs. Included are youth facts, funding information, and tools to help you assess community assets, generate maps of local and federal resources, search for evidence-based youth programs, and keep up-to-date on the latest, youth-related news.",
      link: "https://youth.gov/youth-topics/homelessness-and-runaway",
    },
  },
};

export default Copy;
