import useStyles from "./Input.styles";
import InputUnstyled from "@mui/base/InputUnstyled";
import { FormControl, InputLabel, FormHelperText } from "@mui/material";

type IProps = {
  id: string;
  label: string;
  value: string | undefined;
  name: string;
  placeholder?: string;
  textarea?: boolean;
  required?: boolean;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error: boolean | undefined;
  helperText?: string;
};

const Input = (props: IProps) => {
  const classes = useStyles();
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  return (
    <FormControl error={props.error} className={classes.inputWrapper}>
      <InputLabel htmlFor={props.id} required={props.required ? true : false}>
        {props.label}
      </InputLabel>
      <InputUnstyled
        className={props.error ? classes.errorInput : undefined}
        id={props.id}
        required={props.required}
        multiline={props.textarea ? true : false}
        onChange={(e) => props.onChange(e)}
        onBlur={handleBlur}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
      />
      {props.error ? (
        <FormHelperText id={props.id}>{props.helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default Input;
