// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsbeTN-rJytclbmHchhkF6rxLkniuepXc",
  authDomain: "dpc-youth-up.firebaseapp.com",
  projectId: "dpc-youth-up",
  storageBucket: "dpc-youth-up.appspot.com",
  messagingSenderId: "997242526300",
  appId: "1:997242526300:web:ba3d9b513c82619661e499",
  measurementId: "G-8JKSQW6N1J",
};

// Initialize and export Firebase analytics
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics;
