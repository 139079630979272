import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  navigationSm: {
    position: "absolute",
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    top: theme.spacing(8),
    right: theme.spacing(0),
    transform: "translateX(0px)",
    transition: "1s",
    backgroundColor: "#ffffff",
    border: "2px solid #f27b1f",
    width: "320px",
    height: "841px",
    [theme.breakpoints.up("md")]: {
      top: theme.spacing(11),
      width: "400px",
      height: "833px",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  closedNav: {
    transform: "translateX(325px)",
    transition: ".5s",
    [theme.breakpoints.up("md")]: {
      transform: "translateX(425px)",
    },
  },
  closeBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
    margin: `${theme.spacing(3)} ${theme.spacing(0)}`,
    "& .MuiIconButton-root": {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
    },
  },
  navigationLg: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
    },
  },
}));

export default useStyles;
