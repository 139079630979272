import NavLink from "./NavLink";
import { ReactComponent as Close } from "../../assets/icons/ic-close.svg";
import { IconButton } from "@mui/material";
import useStyles from "./Navigation.styles";

type Props = {
  sm?: boolean;
  mobileMenuOpen?: boolean;
  onClickClose?: () => void;
};

const Navigation = (props: Props) => {
  const classes = useStyles();
  return props.sm && props.onClickClose ? (
    <nav
      className={
        props.mobileMenuOpen
          ? classes.navigationSm
          : `${classes.navigationSm} ${classes.closedNav}`
      }
    >
      <div className={classes.closeBtnContainer}>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label="close"
          onClick={props.onClickClose}
        >
          <Close />
        </IconButton>
      </div>
      <NavLink href="/" onClick={props.onClickClose}>
        Home
      </NavLink>
      <NavLink href="/emergency" onClick={props.onClickClose}>
        Emergency Services
      </NavLink>
      <NavLink href="/assistance" onClick={props.onClickClose}>
        Other Quick Assistance Links
      </NavLink>
      <NavLink href="/faqs" onClick={props.onClickClose}>
        FAQs
      </NavLink>
      <NavLink href="/rights" onClick={props.onClickClose}>
        Know Your Rights
      </NavLink>
      <NavLink href="/contact" onClick={props.onClickClose}>
        Connect With Us
      </NavLink>
    </nav>
  ) : (
    <nav className={classes.navigationLg}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/emergency">Emergency</NavLink>
      <NavLink href="/assistance">Quick Assistance</NavLink>
      <NavLink href="/faqs">FAQs</NavLink>
      <NavLink href="/rights">Rights</NavLink>
      <NavLink href="/contact">Contact</NavLink>
    </nav>
  );
};

export default Navigation;
