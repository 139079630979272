import { Typography, Divider } from "@mui/material";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import useStyles from "./EmergencyServicesPage.styles";
import Copy from "../../utils/data/copy";
import { Helmet } from "react-helmet";

const EmergencyServicesPage = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>Youth Up - Emergency Services</title>
      </Helmet>
      <div>
        <PageTitleBanner title="Emergency Services"></PageTitleBanner>
        <div className={classes.contentContainer}>
          <div className={classes.section}>
            <Typography variant="h2">
              {Copy.EmergencyServices.section1.title}
            </Typography>
            <Typography variant="body1">
              {Copy.EmergencyServices.section1.body}
            </Typography>
            <Typography>
              <span className={classes.label}>Phone:</span>
              <a href={`tel:${Copy.EmergencyServices.section1.title}`}>
                Dial {Copy.EmergencyServices.section1.phoneNumber}
              </a>
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Typography variant="h2">
              {Copy.EmergencyServices.section2.title}
            </Typography>
            <Typography variant="body1">
              {Copy.EmergencyServices.section2.body}
            </Typography>
            <Typography>
              <span className={classes.label}>Phone:</span>
              <a href={`tel:${Copy.EmergencyServices.section2.title}`}>
                Dial {Copy.EmergencyServices.section2.phoneNumber}
              </a>
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Typography variant="h2">
              {Copy.EmergencyServices.section3.title}
            </Typography>
            <Typography variant="body1">
              {Copy.EmergencyServices.section3.body}
            </Typography>
            <Typography>
              <span className={classes.label}>Phone:</span>
              <a href={`tel:${Copy.EmergencyServices.section3.phoneNumber}`}>
                {Copy.EmergencyServices.section3.phoneNumber}
              </a>
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Typography variant="h2">
              {Copy.EmergencyServices.section4.title}
            </Typography>
            <Typography>
              <span className={classes.label}>
                {Copy.EmergencyServices.section4.subTitle}
              </span>
            </Typography>
            <Typography variant="body1">
              {Copy.EmergencyServices.section4.body}
            </Typography>
            <Typography>
              <span className={classes.label}>Phone:</span>
              <a href={`tel:${Copy.EmergencyServices.section4.phoneNumber}`}>
                {Copy.EmergencyServices.section4.phoneNumber}
              </a>
            </Typography>
          </div>
          <Divider className={classes.divider} />
          <div className={classes.section}>
            <Typography variant="h2">
              {Copy.EmergencyServices.section5.title}
            </Typography>
            <Typography variant="body1">
              {Copy.EmergencyServices.section5.body}
            </Typography>
            <Typography>
              <span className={classes.label}>Phone:</span>
              <a href={`tel:${Copy.EmergencyServices.section5.phoneNumber}`}>
                {Copy.EmergencyServices.section5.phoneNumber}
              </a>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmergencyServicesPage;
