import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  navLink: {
    fontFamily: "Montserrat, sans-serif",
    fontStyle: "normal",
    fontSize: 26,
    lineHeight: "30px",
    fontWeight: "bold",
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
    margin: `${theme.spacing(3)} ${theme.spacing(0)}`,
    textDecoration: "none",
    "&:hover": {
      color: "#f27b1f",
    },
    [theme.breakpoints.up("lg")]: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "20px",
      padding: `${theme.spacing(1.5)} ${theme.spacing(1.875)}`,
    },
  },
}));

export default useStyles;
