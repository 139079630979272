import { Typography } from "@mui/material";
import useStyles from "./Footer.styles";
import { phoneNumberFormatted, address } from "../../utils/global-values";
import { ReactComponent as Facebook } from "../../assets/icons/ic-facebook.svg";
import { ReactComponent as Instagram } from "../../assets/icons/ic-instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/ic-linkedin.svg";
import { ReactComponent as Podcast } from "../../assets/icons/ic-podcast.svg";

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      <Typography variant="h1">Detroit Phoenix Center</Typography>
      <Typography className={classes.addressName}>{address[0]}</Typography>
      <Typography className={classes.addressBody}>
        {`${address[1]} ${address[2]}`}
        <br />
        <span className={classes.dayOfWeek}>Mondays &amp; Wednesdays: </span>
        11 am - 5 pm (For ages 13-17)
        <br />
        <span className={classes.dayOfWeek}>Fridays &amp; Sundays: </span>11 am
        - 5 pm (For ages 18-24)
      </Typography>
      <Typography className={classes.hotline}>
        DPC Hotline:{" "}
        <a href={`tel:${phoneNumberFormatted}`}>{phoneNumberFormatted}</a> (10
        am - 6 pm EST)
      </Typography>
      <div className={classes.socialIcons}>
        <a
          className={classes.facebookIcon}
          href="https://www.facebook.com/detroitphoenixcenter/"
          target="_blank"
          rel="noreferrer"
        >
          <Facebook />
        </a>
        <a
          className={classes.instaIcon}
          href="https://www.instagram.com/detroitphoenixcenter/"
          target="_blank"
          rel="noreferrer"
        >
          <Instagram />
        </a>
        <a
          className={classes.linkedinIcon}
          href="https://www.linkedin.com/company/detroit-phoenix-center/about/"
          target="_blank"
          rel="noreferrer"
        >
          <Linkedin />
        </a>
        <a
          className={classes.podcastIcon}
          href="https://www.detroitphoenixcenter.org/podcast"
          target="_blank"
          rel="noreferrer"
        >
          <Podcast />
        </a>
      </div>
      <div className={classes.finePrint}>
        <a href="mailto:info@detroitphoenixcenter.org">
          info@detroitphoenixcenter.org
        </a>
        <br />
        <Typography className={classes.bold}>
          &copy;2016-{new Date().getFullYear()} by Detroit Phoenix Center
        </Typography>
      </div>
    </div>
  );
};

export default Footer;
