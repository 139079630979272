import { Typography } from "@mui/material";
import useStyles from "./PageTitleBanner.styles";

type IProps = {
  title: string;
};

const PageTitleBanner = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.layoutContainer}>
      <div className="container">
        <Typography variant="h1" className="text">
          {props.title}
        </Typography>
      </div>
    </div>
  );
};

export default PageTitleBanner;
