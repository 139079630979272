import { makeStyles } from "@mui/styles";
import backgroundImageSmall from "../../assets/images/pageTitleBackground-small.svg";
import backgroundImage768 from "../../assets/images/pageTitleBackground-768.svg";
import backgroundImage1024 from "../../assets/images/pageTitleBackground-1024.svg";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  layoutContainer: {
    position: "relative",
    left: "50%",
    /* width 0 prevents affecting parent size */
    width: 0,
    "& .container": {
      boxSizing: "border-box",
      width: "100vw",
      padding: `${theme.spacing(3.5)} ${theme.spacing(2)}`,
      minHeight: "86px",
      transform: "translateX(-50vw)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage: `url(${backgroundImageSmall})`,
      [theme.breakpoints.up("md")]: {
        padding: `${theme.spacing(4.5)} ${theme.spacing(2)}`,
        minHeight: "162px",
        backgroundImage: `url(${backgroundImage768})`,
      },
      [theme.breakpoints.up("lg")]: {
        padding: `${theme.spacing(5.9375)} ${theme.spacing(2)}`,
        minHeight: "186px",
        backgroundImage: `url(${backgroundImage1024})`,
      },
      "& .text": {
        margin: 0,
        [theme.breakpoints.up("md")]: {
          lineHeight: "50px",
        },
        [theme.breakpoints.up("lg")]: {
          lineHeight: "70px",
        },
      },
    },
  },
}));

export default useStyles;
