import { Typography } from "@mui/material";
import Button from "../../components/Button/Button";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import useStyles from "./KnowYourRights.styles";
import Copy from "../../utils/data/copy";
import { Helmet } from "react-helmet";

const KnowYourRights = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>YouthUp - Know Your Rights</title>
      </Helmet>
      <div>
        <PageTitleBanner title="Know Your Rights" />
        <div className={classes.contentContainer}>
          <Typography variant="h2" className={classes.title}>
            Youth and Homelessness
          </Typography>
          <div className={classes.section}>
            <Typography variant="h2" component="h3">
              {Copy.KnowYourRights.section1.title}
            </Typography>
            <Typography>{Copy.KnowYourRights.section1.body}</Typography>
            <Button blue link href={Copy.KnowYourRights.section1.link}>
              {Copy.KnowYourRights.section1.title}
            </Button>
          </div>
          <div className={classes.section}>
            <Typography variant="h2" component="h3">
              {Copy.KnowYourRights.section2.title}
            </Typography>
            <Typography>{Copy.KnowYourRights.section2.body}</Typography>
            <Button blue link href={Copy.KnowYourRights.section2.link}>
              {Copy.KnowYourRights.section2.title}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KnowYourRights;
