import Button from "./Button/Button";
import PageTitleBanner from "./PageTitleBanner/PageTitleBanner";
import EmergencyModal from "./Modals/EmergencyModal";
import Input from "./Form/Input";
import React from "react";
import Form from "./Form/Form";
import { Typography } from "@mui/material";
import Resource from "./Resource/Resource";

const Components = () => {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string | undefined>();
  const [textAreaValue, setTextAreaValue] = React.useState<
    string | undefined
  >();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputValue(e.currentTarget.value);
  };

  const handleTextAreaChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTextAreaValue(e.currentTarget.value);
  };

  return (
    <div
      style={{
        marginBottom: "48px",
      }}
    >
      <PageTitleBanner title="A Page for Developing and Testing Components" />
      <h2>Button Component</h2>
      <div>
        <Button
          blue
          onClick={() => {
            alert("clicked blue button");
          }}
        >
          Blue Button
        </Button>
        <Button
          orangePrimary
          onClick={() => {
            alert("clicked orange primary button");
          }}
        >
          Orange Primary Button
        </Button>
        <Button
          orangeSecondary
          onClick={() => {
            alert("clicked orange secondary button");
          }}
        >
          Orange Secondary Button
        </Button>
      </div>
      <h2>Input</h2>
      <div>
        <Input
          id="sample-input"
          label="Input with Error"
          name="input"
          onChange={handleInputChange}
          value={inputValue}
          placeholder="Placeholder"
          error={true}
        />
        <Input
          required
          id="sample-input"
          label="Input Required"
          name="input"
          onChange={handleInputChange}
          value={inputValue}
          placeholder="Placeholder"
          error={false}
        />
        <Input
          textarea
          id="sample-textarea"
          label="Textarea"
          name="textarea"
          onChange={handleTextAreaChange}
          value={textAreaValue}
          placeholder="Placeholder"
          error={false}
        />
      </div>
      <h2>Form</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Form />
      </div>
      <h2>Emergency Modal Component</h2>
      <Button onClick={() => setOpen(true)} blue>
        Open Modal
      </Button>
      <EmergencyModal open={open} setOpen={setOpen} />
      <h2>Typography Theme</h2>
      <Typography variant="h1">H1</Typography>
      <Typography variant="h2">H2</Typography>
      <Typography variant="body1">body1</Typography>
      <Typography variant="body2">
        body2 - line height is the only difference
      </Typography>
      <h2>Resource Component</h2>
      <Resource
        key={1}
        organization="App for a Cause"
        description="Helping our community with our gifts and talents"
        website="www.detroitlabs.com"
        social="www.detroitlabs.com"
        contact="111-111-1111"
        notes="Closed on Saturdays"
      ></Resource>
    </div>
  );
};
export default Components;
