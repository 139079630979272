import { forceHttps } from "../../utils/urlFormatter";
import { Typography } from "@mui/material";
import useStyles from "./Resource.styles";

type IProps = {
  organization: string;
  description: string;
  website?: string | null;
  social?: string | null;
  contact?: string | null;
  address?: string | null;
  notes?: string | null;
};

const Resource = (props: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="body2" component="h3">
        {props.organization}
      </Typography>
      <Typography variant="body2">{props.description}</Typography>
      {props.website ? (
        <Typography>
          <span className={classes.label}>Website:</span>
          <a href={forceHttps(props.website)} target="_blank" rel="noreferrer">
            {props.website}
          </a>
        </Typography>
      ) : null}
      {props.social ? (
        <Typography>
          <span className={classes.label}>Social:</span>{" "}
          <a href={props.social} target="_blank" rel="noreferrer">
            {props.social}
          </a>
        </Typography>
      ) : null}
      {props.contact ? (
        <Typography>
          <span className={classes.label}>Phone:</span>
          <a href={`tel:${props.contact}`}>{props.contact}</a>
        </Typography>
      ) : null}
      {props.address ? (
        <Typography>
          <span className={classes.label}>Address:</span>
          {props.address}
        </Typography>
      ) : null}
      {props.notes ? (
        <Typography>
          <span className={classes.label}>Notes:</span> {props.notes}
        </Typography>
      ) : null}
    </div>
  );
};

export default Resource;
