import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    "& .MuiAccordion-root:first-child": {
      "& .MuiAccordionSummary-root": {
        alignItems: "flex-start",
      },
      "& .MuiAccordionSummary-content": {
        marginTop: theme.spacing(0),
      },
    },
    "& .MuiAccordion-root:last-child": {
      borderBottom: "unset",
    },
  },
  accordion: {
    borderBottom: "1px solid #4B5563",
    "&.MuiPaper-root": {
      boxShadow: "none",
      "&::before": {
        display: "none",
      },
      "&.Mui-expanded": {
        margin: theme.spacing(0),
      },
    },
    "& .MuiButtonBase-root.MuiAccordionSummary-root": {
      padding: theme.spacing(0),
    },
    "& .MuiAccordionSummary-content": {
      margin: `${theme.spacing(3)} ${theme.spacing(0)}`,
      "& .MuiTypography-h2": {
        marginBottom: theme.spacing(0),
      },
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: theme.spacing(0),
    },
    "& div:last-of-type": {
      borderBottom: "none",
      marginBottom: "unset",
    },
  },
}));

export default useStyles;
