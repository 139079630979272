import { Container, Typography } from "@mui/material";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import Form from "../../components/Form/Form";
import useStyles from "./Contact.styles";
import { phoneNumberFormatted } from "../../utils/global-values";
import { Helmet } from "react-helmet";

const Contact = () => {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>YouthUp - Contact</title>
      </Helmet>
      <div className={classes.contactContainer}>
        <PageTitleBanner title="Connect With Us" />
        <Container id="contentsContainer">
          <Typography variant="body1" id="formDescription">
            <span className="bold">Please note:</span> An external email
            application is required to send messages to Detroit Phoenix Center.
            If you do not have email setup on your device, please call DPC
            directly at{" "}
            <a href={`tel:${phoneNumberFormatted}`}>{phoneNumberFormatted}</a>.
          </Typography>
          <Form />
        </Container>
      </div>
    </>
  );
};

export default Contact;
