import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { ReactComponent as Close } from "../../assets/icons/ic-close.svg";
import useStyles from "./Modal.styles";

type IProps = {
  open: boolean;
  setOpen: (arg: boolean) => void;
  children: React.ReactNode;
};

const DropInServicesModal = (props: IProps) => {
  const classes = useStyles();

  const onClickClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      className={`${classes.modal} ${classes.dropInModal}`}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={props.open}
      onClose={() => onClickClose()}
    >
      <Box>
        <div className={classes.closeBtnContainer}>
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => onClickClose()}
          >
            <Close />
          </IconButton>
        </div>
        {props.children}
      </Box>
    </Modal>
  );
};

export default DropInServicesModal;
