import { Box, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>YouthUp - Not Found</title>
      </Helmet>
      <PageTitleBanner title="Page Not Found" />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
        }}
      >
        <Typography variant="body1">
          404 - The page you are looking for cannot be found. Please return
          <NavLink to="/" style={{ marginLeft: "5px" }}>
            home
          </NavLink>
          .
        </Typography>
      </Box>
    </>
  );
};

export default NotFound;
