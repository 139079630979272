import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  button: {
    "&.MuiButton-root, &.MuiLink-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "12px",
      padding: "6px 16px",
      width: "100%",
      height: "48px",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: "4px",
      color: "#F7F8F7",
      textTransform: "none",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "20px",
      boxSizing: "border-box",
    },
  },
  blue: {
    "&.MuiButton-root, &.MuiLink-root": {
      backgroundColor: "#142C74",
      "&:hover": {
        backgroundColor: "#142C74",
      },
    },
  },
  orangePrimary: {
    "&.MuiButton-root, &.MuiLink-root": {
      backgroundColor: "#F27B1F",
      "&:hover": {
        backgroundColor: "#F27B1F",
      },
    },
  },
  orangeSecondary: {
    "&.MuiButton-root, &.MuiLink-root": {
      backgroundColor: "#FFFFFF",
      color: "#F27B1F",
      border: "1px solid #F27B1F",
      "&:hover": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
});

export default useStyles;
