import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme/createTheme";

const useStyles = makeStyles<Theme>((theme) => ({
  navbarContainer: {
    height: 64,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: 88,
    },
    [theme.breakpoints.up("lg")]: {
      height: 108,
    },
    "& .MuiAppBar-root": {
      position: "sticky",
      zIndex: 2,
    },
  },
  toolbar: {
    justifyContent: "space-between",
    height: 64,
    padding: theme.spacing(0),
    backgroundColor: "#ffffff",
    [theme.breakpoints.up("md")]: {
      height: 88,
    },
    [theme.breakpoints.up("lg")]: {
      height: 108,
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
  },
  navLogoTextContainer: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: 119,
    height: 27,
    [theme.breakpoints.up("md")]: {
      width: 213,
      height: 48,
    },
  },
  navbarText: {
    "&.MuiTypography-body2": {
      fontFamily: "Inter",
      fontWeight: 400,
      fontStyle: "italic",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#000000",
      marginLeft: "21px",
      [theme.breakpoints.up("md")]: {
        marginLeft: "72px",
      },
      [theme.breakpoints.up("lg")]: {
        marginLeft: "18px",
      },
    },
  },
}));

export default useStyles;
