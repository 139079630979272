import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import PageTitleBanner from "../../components/PageTitleBanner/PageTitleBanner";
import Resource from "../../components/Resource/Resource";
import useStyles from "./QuickAssistancePage.styles";
import { ReactComponent as ChevronDown } from "../../assets/icons/ic-chevron-down.svg";
import React from "react";
import {
  getSectionData,
  IResourcesData,
  resourceSections,
} from "../../utils/resourcesInterfaceV3";
import { Helmet } from "react-helmet";

const QuickAssistancePage = () => {
  const [data, setData] = React.useState<IResourcesData>();
  const classes = useStyles();

  function api<T>(): Promise<T> {
    return fetch("/resourcesV3.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        const errorMessage = "Error loading data from";
        return Promise.reject(errorMessage);
      }
      return response.json().then((data) => data as T);
    });
  }

  React.useEffect(() => {
    api<IResourcesData>().then((data) => {
      if (data) setData(data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>YouthUp - Quick Assistance</title>
      </Helmet>
      <div>
        <PageTitleBanner title="Other Quick Assistance Links"></PageTitleBanner>
        <div className={classes.container}>
          {resourceSections.map((res, key) => {
            return (
              <Accordion square className={classes.accordion} key={key}>
                <AccordionSummary
                  expandIcon={<ChevronDown />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h2">{res}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {data !== undefined
                    ? getSectionData(data, res)?.map((r, key) => (
                        <React.Fragment key={key}>
                          <Resource
                            organization={r.organization}
                            description={r.description}
                            website={r.website}
                            social={r.social}
                            contact={r.contact}
                            address={r.address}
                            notes={r.notes}
                          ></Resource>
                        </React.Fragment>
                      ))
                    : null}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default QuickAssistancePage;
