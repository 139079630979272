import { createTheme } from "@mui/material";
import breakpoints from "./breakpoints";

const SPACING_BASE = 8;
const spacing = (factor: number) => `${SPACING_BASE * factor}px`;

const theme = createTheme({
  breakpoints,
  spacing,
  typography: {
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 28,
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "30px",
      letterSpacing: "0px",
      textAlign: "center",
      "@media screen and (min-width: 768px)": {
        fontSize: 50,
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: 64,
      },
    },
    h2: {
      marginBottom: 12,
      fontFamily: "Montserrat",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "30px",
      letterSpacing: "0px",
      textAlign: "left",
      "@media screen and (min-width: 768px)": {
        fontSize: 26,
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: 28,
      },
    },
    body1: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0px",
      textAlign: "left",
      "@media screen and (min-width: 768px)": {
        fontSize: 18,
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: 20,
      },
    },
    body2: {
      fontFamily: "Poppins",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "30px",
      letterSpacing: "0px",
      textAlign: "left",
      "@media screen and (min-width: 768px)": {
        fontSize: 18,
      },
      "@media screen and (min-width: 1024px)": {
        fontSize: 20,
      },
    },
  },
});

export default theme;
